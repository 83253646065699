<template>
    <div class="mt-4">
        <!-- This is a read-only setting -->
    </div>
</template>
<script>

export default {
    name: 'CatalogProductSettingClothingVariants',
    props: {
        value: {
            type: Object,
            default: () => {},
        },
    },
    data () {
        return {
            configuration: {},
        }
    },
}
</script>
<style scoped lang="scss">
.float-left {
    float: left;
    margin-left: -13px;
}
.d-inline-block+.d-inline-block {
    margin-left: 15px;
}
</style>
